import React from 'react';
import { graphql } from 'gatsby';
import Container from '../components/layout/container';
import GraphQLErrorList from '../components/graphql-error-list';
import SEO from '../components/layout/seo';
import ContentSections from '../components/pagebuilder/content-sections';
import { GlobalStyle } from '../components/sections/cycle/cycle.style';
import Navigation from '../components/sections/cycle/Navigation';
import SectionHero from '../components/pagebuilder/section-hero';

export const query = graphql`
  query PageTemplateQuery($id: String!) {
    page: sanityPage(id: { eq: $id }) {
      ...Page
    }
  }
`;

const PageTemplate = (props) => {
  const {
    data: { page },
    errors,
  } = props;

  const { title, seo, seoImage, hero, smallHero } = page;
  const seoDescription = seo?.meta_description;
  const pageTitle = title;
  const seoTitle = seo?.seo_title;
  return (
    <>
      <GlobalStyle />
      <Navigation />
      <SectionHero smallHero={smallHero} hero={hero} />
      {errors && <SEO title="GraphQL Error" />}
      {page && (
        <SEO
          title={pageTitle}
          seoImage={seoImage?.asset?.img?.src}
          seoTitle={seoTitle}
          description={seoDescription}
          keywords={page.seoKeywords}
          path={props.location.pathname}
        />
      )}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )}

      {page && page.content && <ContentSections content={page.content} />}
    </>
  );
};

export default PageTemplate;
